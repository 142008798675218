import { Button, CheckIcon } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { repaymentDfaConfirmByInvestor, repaymentDfaConfirmByInvestorPrefly } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { ConfirmRepaymentModal } from '../Modals/ConfirmRepaymentModal/ConfirmRepaymentModal'
import { IActionProps } from './type'

export const ConfirmRepaymentAction: FC<IActionProps> = memo(({ dfa }) => {
    const reduxDispatch = useDispatch()
    const isInvestorRepaymentCheck = useSelector(dfaDetailsSelector.selectIsInvestorRepaymentCheck)
    const fullBalance = useSelector(dfaDetailsSelector.selectFullBalance)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const isActionAvailable =
        isInvestorRepaymentCheck &&
        fullBalance > 0 &&
        dfa?.balance?.is_investor_repayment_confirmed === false

    const confirmRepayment = (skid: string) => {
        if (dfa?.id) {
            reduxDispatch(repaymentDfaConfirmByInvestorPrefly({ asset_id: dfa.id, skid }))
        }
    }

    const signRepayment = (skid: string) => {
        if (dfa?.id) {
            reduxDispatch(repaymentDfaConfirmByInvestor({ asset_id: dfa.id, skid }))
            setIsModalVisible(false)
        }
    }

    if (isActionAvailable) {
        return (
            <>
                <Button
                    borderRadius={16}
                    onClick={() => setIsModalVisible(true)}
                    type="primary"
                    icon={<CheckIcon />}
                >
                    Согласен на погашение
                </Button>
                {isModalVisible && (
                    <ConfirmRepaymentModal
                        isModalVisible={isModalVisible}
                        onOk={confirmRepayment}
                        onSignOk={signRepayment}
                        onCancel={() => {
                            setIsModalVisible(false)
                        }}
                    />
                )}
            </>
        )
    }

    return null
})
