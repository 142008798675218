import { IBankDetails } from '@dltru/dfa-models'

import api from '@services/api'

export const getBankDetailsByUserId = async (userId: string): Promise<IBankDetails | undefined> => {
    try {
        const { data, error } = await api.lib.getBankDetailsByUserIdService(userId)

        if (error) {
            throw new Error(error.toString())
        }

        return data.item
    } catch (error) {
        console.error(error)
        return undefined
    }
}
