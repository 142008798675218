import { FC, memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DfaStatusEnum, SettlementsType } from '@dltru/dfa-models'
import { Button, CaseDel } from '@dltru/dfa-ui'

import { ordersSelector } from '@store/orders/list/selectors'

import FailedIssueModal from '../Modals/FailedIssueModal/FailedIssueModal'

import { IActionProps } from './type'

export const SetEmitmentAsInvalidAction: FC<IActionProps> = memo(({ status, isEmitter, dfa }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isHasAnyStatusData = useSelector(ordersSelector.isHasAnyStatusData)

    const isActionAvailable =
        DfaStatusEnum.collection_started === status &&
        isEmitter &&
        dfa.repayment_settlements_type === SettlementsType.direct &&
        !isHasAnyStatusData

    if (isActionAvailable) {
        return (
            <>
                <Button
                    icon={<CaseDel width="20" height="20" />}
                    borderRadius={16}
                    onClick={() => {
                        setIsModalVisible(true)
                    }}
                >
                    Признать выпуск несостоявшимся
                </Button>

                {isModalVisible && (
                    <FailedIssueModal
                        isModalVisible={isModalVisible}
                        setIsModalVisible={() => setIsModalVisible(false)}
                        dfaId={dfa?.id}
                    />
                )}
            </>
        )
    }

    return null
})
