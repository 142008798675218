import { CheckIcon } from '@dltru/dfa-ui'
import { FC } from 'react'

import { IActionProps } from '../type'
import style from './ConfirmAction.m.less'

export const ConfirmAction: FC<IActionProps> = ({ dfa }) => {
    if (dfa?.balance?.is_investor_repayment_confirmed) {
        return (
            <div className={style.ConfirmCell}>
                <CheckIcon />
                <span>Вы дали свое согласие на погашение</span>
            </div>
        )
    }

    return null
}
