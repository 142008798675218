import { IDfaOwnerBalance } from '@dltru/dfa-models'
import { Button, DocumentPrevious } from '@dltru/dfa-ui'
import { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'

interface IComponentProps {
    balance?: IDfaOwnerBalance
    isPartiallyBlocked?: boolean
}

export const CreateEncumbranceAction: FC<IComponentProps> = memo(
    ({ balance, isPartiallyBlocked }) => {
        const navigate = useNavigate()
        const showButton = balance?.active_amount_dfa
        if (!showButton) {
            return null
        }

        return (
            <Button
                borderRadius={16}
                onClick={() => navigate('encumbrance')}
                icon={<DocumentPrevious />}
                disabled={isPartiallyBlocked}
                tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
            >
                Наложить обременение
            </Button>
        )
    },
)
