import { FC, memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DfaStatusEnum, PriceSource } from '@dltru/dfa-models'
import { Button, ShoppingCart } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { TradeBuyingModal } from '@components/Modals/Orders'

import { IActionProps } from './type'

export const ApplyTradeAction: FC<IActionProps> = memo(({ isPartiallyBlocked }) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isBlockUnqual = useSelector(profileSelector.selectBlockUnqual)
    const currentUser = useSelector(authSelector.selectUserUid)

    const isEmitter = currentUser === dfaDetails.emitter_id

    if (isBlockUnqual) {
        return null
    }

    const isActionAvailable =
        DfaStatusEnum.collection_started === dfaDetails.status &&
        !isEmitter &&
        (!dfaDetails.is_limited_circle_investors ||
            (currentUser && dfaDetails.investors?.includes(currentUser)))
    const isDfaAvailable =
        dfaDetails.price_source_type === PriceSource.dynamic ||
        dfaDetails.available?.rest_amount_dfa

    if (isActionAvailable) {
        return (
            <>
                <Button
                    icon={<ShoppingCart />}
                    borderRadius={16}
                    type="primary"
                    onClick={() => {
                        setIsModalVisible(true)
                    }}
                    disabled={isPartiallyBlocked || !isDfaAvailable}
                    tooltipOnDisable={
                        isPartiallyBlocked
                            ? 'Действие заблокировано. Обратитесь в службу поддержки.'
                            : ''
                    }
                >
                    Подать заявку
                </Button>

                {isModalVisible && (
                    <TradeBuyingModal
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                    />
                )}
            </>
        )
    }

    return null
})
