import { DfaStatusEnum, RedemptionTypeDfa } from '@dltru/dfa-models'
import { Button, EmptyWalletAdd } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

import DayJSUtc from 'dayjs/plugin/utc'
import DayJSTimezone from 'dayjs/plugin/timezone'

import { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { RepaymentModal } from '../Modals/RepaymentModal/RepaymentModal'
import { SetRepaymentPrice } from '../Modals/SetRepaymentPrice'
import { IActionProps } from './type'

dayjs.extend(DayJSUtc)
dayjs.extend(DayJSTimezone)

export const RepaymentAction: FC<IActionProps> = () => {
    const isForRepaymentPlatform = useSelector(dfaDetailsSelector.selectIsForPlatformRepayment)
    const isForDirectRepayment = useSelector(dfaDetailsSelector.selectIsForDirectRepayment)
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const [isShowRedeemModal, setIsShowRedeemModal] = useState(false)
    const [isShowRedeemPriceModal, setIsShowRedeemPriceModal] = useState(false)
    
    const redeem_date = dfa?.redeem_date ?? 0

    const isForRepaymentDirectWithStatus =
        DfaStatusEnum.emitment_success === dfa?.status && isForDirectRepayment

    const isForRepaymentPlatformWithDate =
        isForRepaymentPlatform &&
        dfa?.redeem_date &&
        dayjs(redeem_date).tz('Europe/Moscow').startOf('day').diff(dayjs(), 'seconds') <= 0

    if (!dfa?.id || !(isForRepaymentPlatformWithDate || isForRepaymentDirectWithStatus)) {
        return null
    }

    const showRedeemModal = () => {
        if (dfa.redemption_type === RedemptionTypeDfa.flexible) {
            setIsShowRedeemPriceModal(true)
        } else {
            setIsShowRedeemModal(true)
        }
    }

    return (
        <>
            <Button
                borderRadius={16}
                type="primary"
                onClick={showRedeemModal}
                // disabled={isPartiallyBlocked} // https://masterchain.atlassian.net/wiki/spaces/DFA/pages/31818415/0+8
                icon={<EmptyWalletAdd />}
            >
                Погасить выпуск
            </Button>
            {isShowRedeemModal && (
                <RepaymentModal
                    onCancel={() => setIsShowRedeemModal(false)}
                    isModalVisible={isShowRedeemModal}
                />
            )}
            {isShowRedeemPriceModal && (
                <SetRepaymentPrice
                    isModalVisible={isShowRedeemPriceModal}
                    setIsModalVisible={setIsShowRedeemPriceModal}
                />
            )}
        </>
    )
}
