import { FC, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DfaStatusEnum } from '@dltru/dfa-models'
import { Button, EditSquare } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { sendToTellerDfa } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

import { SendForApproveModal } from '@components/Modals/Issue'


export const SendForApproveAction: FC = () => {
    const dfa = useSelector(dfaDetailsSelector.selectDfaDetails)
    const currentUser = useSelector(authSelector.selectUserUid)
    const reduxDispatch = useDispatch()
    const { disabledActionButtons } = useContext(EmissionCardPageContext)

    const [isModalVisible, setIsModalVisible] = useState(false)
    const showButton =
        dfa.id &&
        currentUser === dfa.emitter_id &&
        (dfa.status === DfaStatusEnum.modification ||
            dfa.status === DfaStatusEnum.issuer_confirmation)

    if (!showButton) {
        return null
    }

    const handleOk = () => {
        reduxDispatch(sendToTellerDfa(dfa.id))
        setIsModalVisible(false)
    }

    return (
        <>
            <Button
                onClick={() => setIsModalVisible(true)}
                icon={<EditSquare />}
                borderRadius={16}
                type="primary"
                disabled={!disabledActionButtons}
            >
                Подать заявление
            </Button>
            {isModalVisible && (
                <SendForApproveModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    handleOk={handleOk}
                />
            )}
        </>
    )
}