import { FC, memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    DfaStatusEnum,
    EmissionOrderStatus,
    IssueTypeDfa,
    PriceSource,
    SettlementsType,
} from '@dltru/dfa-models'
import { Button, ShoppingCart } from '@dltru/dfa-ui'

import { loadOrdersByDfaId } from '@store/orders/list'
import { ordersSelector } from '@store/orders/list/selectors'

import { SetEmitmentModal } from '../Modals/SetEmitmentModal'

import { IActionProps } from './type'

export const SetEmitmentAction: FC<IActionProps> = memo(
    ({ isEmitter, isPartiallyBlocked, dfa }) => {
        const totalAceptedAmount = useSelector(ordersSelector.selectTotalAceptedAmount)
        const [isModalVisible, setIsModalVisible] = useState(false)
        const orders = useSelector(ordersSelector.selectData)
        const reduxDispatch = useDispatch()

        const isActionAvailable =
            DfaStatusEnum.collection_started === dfa?.status &&
            isEmitter &&
            dfa.issue_type === IssueTypeDfa.manual

        useEffect(() => {
            if (isActionAvailable) {
                reduxDispatch(loadOrdersByDfaId())
            }
        }, [dfa?.id, isActionAvailable])

        const isOrdersExist =
            (dfa?.issue_settlements_type !== SettlementsType.direct && orders?.length) ||
            orders?.some(
                ({ status }) =>
                    status === EmissionOrderStatus.accepted_by_emitter ||
                    status === EmissionOrderStatus.accepted,
            )

        const isMinSupplyInvested =
            dfa?.price_source_type === PriceSource.static ||
            (dfa?.min_supply_invested || 0) <= totalAceptedAmount

        if (isActionAvailable && isOrdersExist && isMinSupplyInvested) {
            return (
                <>
                    <Button
                        icon={<ShoppingCart />}
                        borderRadius={16}
                        type="primary"
                        onClick={() => {
                            setIsModalVisible(true)
                        }}
                        disabled={isPartiallyBlocked}
                        tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
                    >
                        Осуществить выпуск
                    </Button>

                    {isModalVisible && (
                        <SetEmitmentModal
                            dfaId={dfa.id}
                            isModalVisible={isModalVisible}
                            setIsModalVisible={() => setIsModalVisible(false)}
                        />
                    )}
                </>
            )
        }

        return null
    },
)
