import { DfaStatusEnum } from '@dltru/dfa-models'
import { Button, CaseAdd } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { profileSelector } from '@store/profiles/details/selectors'

import { SecondaryTradeBuyingModal } from '@components/Modals/Orders'

import { IActionProps } from './type'

export const BuyDfaAction: FC<IActionProps> = memo(({ isPartiallyBlocked, dfa }) => {
    const isBlockUnqual = useSelector(profileSelector.selectBlockUnqual)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const isActionAvailable = DfaStatusEnum.emitment_success === dfa?.status

    if (isBlockUnqual) {
        return null
    }

    if (isActionAvailable) {
        return (
            <>
                <Button
                    icon={<CaseAdd />}
                    borderRadius={16}
                    type="primary"
                    onClick={() => {
                        setIsModalVisible(true)
                    }}
                    disabled={isPartiallyBlocked}
                    tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
                >
                    Купить ЦФА
                </Button>

                {isModalVisible && (
                    <SecondaryTradeBuyingModal
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        buying={true}
                        assetId={dfa.id}
                        isCreate
                    />
                )}
            </>
        )
    }

    return null
})
