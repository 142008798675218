import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { DfaStatusEnum } from '@dltru/dfa-models'
import { Button, CaseDel } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dealsSelector } from '@store/dfa/deals/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'

import { EmissionCardPageContext } from '@pages/DFAEmission/DFAEmissionCardPage/EmissionCardPageContext'

import { CancelIssueModal } from '../../CancelIssueModal'


export const RejectEmissionAction: FC = () => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const currentUser = useSelector(authSelector.selectUserUid)
    const isEmitter = currentUser === dfaDetails.emitter_id
    const deals = useSelector(dealsSelector.selectData)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { disabledActionButtons } = useContext(EmissionCardPageContext)
    const isActionAvailable =
        dfaDetails?.status &&
        [
            DfaStatusEnum.lawyer_approval,
            DfaStatusEnum.teller_approval,
            DfaStatusEnum.spec_approval,
            DfaStatusEnum.modification,
            DfaStatusEnum.waiting_for_links,
            DfaStatusEnum.issue_stopped,
            DfaStatusEnum.collection_started,
            DfaStatusEnum.issuer_confirmation,
        ].includes(dfaDetails.status) &&
        isEmitter &&
        !deals.length

    if (!isActionAvailable) {
        return null
    }

    return (
        <>
            <Button
                icon={<CaseDel width="20px" height="20px" />}
                borderRadius={16}
                onClick={() => {
                    setIsModalVisible(true)
                }}
                disabled={!disabledActionButtons}
            >
                Отменить выпуск
            </Button>
            {isModalVisible && (
                <CancelIssueModal
                    setIsModalVisible={setIsModalVisible}
                    isModalVisible={isModalVisible}
                />
            )}
        </>
    )
}