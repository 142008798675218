import { FC, memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IBankDetails } from '@dltru/dfa-models'
import { BankAccountDetailsModal, Button, Eye } from '@dltru/dfa-ui'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { dropOrderList, loadAllOrdersByUser } from '@store/orders/list'
import { ordersSelector } from '@store/orders/list/selectors'
import { profileSelector } from '@store/profiles/details/selectors'
import IAppState from '@store/states'

import { getBankDetailsByUserId } from './utils'

export const BankAccountDetailsAction = memo(() => {
    const reduxDispatch = useDispatch()
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const userFullName = useSelector(profileSelector.userFullName)
    const isShowEmitterAccountDetails = useSelector(
        dfaDetailsSelector.selectIsShowEmitterAccountDetails,
    )
    const currentUser = useSelector(authSelector.selectUserUid)
    const isEmitter = currentUser === dfaDetails.emitter_id
    const isHasActiveDataByUser = useSelector((store: IAppState) =>
        ordersSelector.selectHasActiveDataByAssetId(store, dfaDetails?.id),
    )

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [bankDetails, setbankDetails] = useState<IBankDetails | undefined>()

    useEffect(() => {
        const getBankData = async () => {
            const bankData = await getBankDetailsByUserId(dfaDetails.emitter_id)
            setbankDetails(bankData)
        }

        if (
            isHasActiveDataByUser &&
            dfaDetails.emitter_id &&
            !isEmitter &&
            isShowEmitterAccountDetails
        ) {
            getBankData()
        }
    }, [dfaDetails.emitter_id, isHasActiveDataByUser])

    useEffect(() => {
        if (currentUser && !isEmitter && isShowEmitterAccountDetails) {
            reduxDispatch(loadAllOrdersByUser(currentUser))
        }

        return () => {
            reduxDispatch(dropOrderList())
        }
    }, [currentUser, isShowEmitterAccountDetails, isEmitter])

    const paymentTarget = (
        <>
            <p>{`Оплата в рамках сделки приобретения выпуска ${dfaDetails.ticker_symbol}`}</p>
            <p>{`По заявке инвестора, ${userFullName}. НДС не облагается.`}</p>
        </>
    )
    const paymentTargetText = `Оплата в рамках сделки приобретения выпуска ${dfaDetails.ticker_symbol}. По заявке инвестора, ${userFullName}. НДС не облагается.`

    if (!bankDetails) {
        return null
    }

    return (
        <>
            <Button
                icon={<Eye />}
                borderRadius={16}
                onClick={() => {
                    setIsModalVisible(true)
                }}
            >
                Банковские реквизиты
            </Button>

            {isModalVisible && bankDetails ? (
                <BankAccountDetailsModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    title="Банковские реквизиты Эмитена выпуска ЦФА"
                    bodyText="Для оплаты в рамках сделки приобретения совершите банковский перевод по указанным реквизитам."
                    paymentTargetText={paymentTargetText}
                    paymentTarget={paymentTarget}
                    recipient={bankDetails.recipient}
                    recipientInn={bankDetails.recipient_inn}
                    bankDetails={bankDetails}
                />
            ) : null}
        </>
    )
})
