import { UnionDealFront } from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { dealsSelector } from '@store/dfa/deals/selectors'
import { DfaShortInfo } from '@store/dfa/types'

import { gridConfig } from './gridConfig'
import { useNavigate } from 'react-router-dom'
import { earlyRepaymentActions } from '@store/earlyRepayment'
import { DEFAULT_PER_PAGE } from '@dltru/dfa-ui'
import { earlyRepaymentSelector } from '@store/earlyRepayment/selectors'

interface IEarlyRepaymentTableProps {
    dfaInfo: DfaShortInfo
    userUuid: string
}

export const EarlyRepaymentTable: FC<IEarlyRepaymentTableProps> = ({ dfaInfo, userUuid }) => {
    const isEmmiter = userUuid === dfaInfo.emitter_id
    const reduxDispatch = useDispatch()
    const navigate = useNavigate()
    const { items, paging } = useSelector(earlyRepaymentSelector.selectApplications)

    const onPerPageChange = (limit: number) => {
        reduxDispatch(earlyRepaymentActions.getApplications({ limit, asset_id: dfaInfo.id }))
    }

    const onNext = () => {
        reduxDispatch(earlyRepaymentActions.getApplications({ cursor: paging?.cursors?.next }))
    }

    const onPrev = () => {
        reduxDispatch(earlyRepaymentActions.getApplications({ cursor: paging?.cursors?.prev }))
    }

    useEffect(() => {
        reduxDispatch(
            earlyRepaymentActions.getApplications({
                asset_id: dfaInfo.id,
                limit: DEFAULT_PER_PAGE,
                user_uuid: isEmmiter ? undefined : userUuid,
            }),
        )

        return () => {
            reduxDispatch(earlyRepaymentActions.dropEarlyRepaymentItems())
        }
    }, [])

    const onRow = (value: UnionDealFront) => ({
        onClick: async () => {
            navigate(`/early-repayment/${value.uuid}`)
        },
    })

    return (
        <Table
            isLoading={false}
            columns={gridConfig}
            dataSource={items}
            cursors={paging?.cursors}
            onPerPage={onPerPageChange}
            onNext={onNext}
            onPrev={onPrev}
            onRow={onRow}
            clickableRow
            rowKey="id"
        />
    )
}
