// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConfirmCell_fLAZi {\n  color: #52c41a;\n  display: flex;\n  align-items: center;\n}\n.ConfirmCell_fLAZi span {\n  margin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAEmission/DFAEmissionCardPage/components/Footer/Actions/ConfirmAction/ConfirmAction.m.less"],"names":[],"mappings":"AAEA;EACI,cAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAGI;EACI,gBAAA;AADR","sourcesContent":["@import 'style/palette';\n\n.ConfirmCell {\n    color: @green-6;\n    display: flex;\n    align-items: center;\n\n    & span {\n        margin-left: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConfirmCell": "ConfirmCell_fLAZi"
};
export default ___CSS_LOADER_EXPORT___;
