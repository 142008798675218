import { UnionDealFront, getDealTypeTranslateClient, isTransferDeal } from '@dltru/dfa-models'
import {
    ColoredTag,
    ColumnsType,
    DateTimeCell,
    EMPTY_FIELD_VALUE,
    getRublesFromPennies,
} from '@dltru/dfa-ui'
import React from 'react'

const AmountCell = (value: number, record: UnionDealFront) => {
    if (!value) {
        return EMPTY_FIELD_VALUE
    }

    return (
        <ColoredTag type={record.type === 'repayment' ? 'gray' : 'blue'}>
            {value.toLocaleString()}
        </ColoredTag>
    )
}

const DateByTypeCell = (_, record: UnionDealFront) => {
    if (record.type === 'transfer_buy' || record.type === 'transfer_sell') {
        return DateTimeCell(record.created_at)
    }

    return DateTimeCell(record.executed_at)
}

const TotalPriceCell = (_: number, record: UnionDealFront) => {
    if (record.type === 'transferV2') {
        return EMPTY_FIELD_VALUE
    }

    if (record.type === 'repayment') {
        return record.total_price ? getRublesFromPennies(record.total_price) : 0
    }
    if (isTransferDeal(record)) {
        return record.total_price ? getRublesFromPennies(record.total_price) : EMPTY_FIELD_VALUE
    }
    if (record.price_per_dfa && record.amount_dfa) {
        return getRublesFromPennies(record.price_per_dfa * record.amount_dfa)
    }

    return 0
}

export const gridConfig: ColumnsType<UnionDealFront> = [
    {
        title: 'Дата и время',
        dataIndex: 'executed_at',
        key: 'executed_at',
        sorter: true,
        ellipsis: true,
        width: '15%',
        render: DateByTypeCell,
    },
    {
        title: 'Тип сделки',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        width: '15%',
        render: getDealTypeTranslateClient,
    },
    {
        title: 'Кол-во, ед.',
        dataIndex: 'amount_dfa',
        key: 'amount_dfa',
        sorter: true,
        width: '10%',
        render: AmountCell,
    },
    {
        title: 'Цена, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        render: (value: number, record: UnionDealFront) => {
            if (record.type === 'repayment') {
                return value ? getRublesFromPennies(value) : 0
            }
            return value ? getRublesFromPennies(value) : EMPTY_FIELD_VALUE
        },
        width: '10%',
    },
    {
        title: 'Сумма сделки, ₽',
        dataIndex: 'sum',
        key: 'sum',
        sorter: true,
        width: '10%',
        render: TotalPriceCell,
    },
]
