import {
    UnionDealFront,
    isEmissionDeal,
    isRepaymentDeal,
    isSecondaryDeal,
    isTransferDeal,
} from '@dltru/dfa-models'
import { Table } from '@dltru/dfa-ui'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { clearDfaDeals, getDfaDealsById } from '@store/dfa/deals'
import { dealsSelector } from '@store/dfa/deals/selectors'
import { DfaShortInfo } from '@store/dfa/types'

import { emissionModal } from '@components/Modals/Deals/View/Issue'
import { repaymentModal } from '@components/Modals/Deals/View/Repayment'
import { secondaryModal } from '@components/Modals/Deals/View/Secondary'
import { transferModal } from '@components/Modals/Deals/View/Transfer'

import { gridConfig } from './gridConfig'
import { transferV2Modal } from './TransferV2Modal'

interface IComponentProps {
    dfaInfo: DfaShortInfo
    uuid: string
}

export const DealsTable: FC<IComponentProps> = ({ dfaInfo, uuid }) => {
    const reduxDispatch = useDispatch()
    const dfaId = dfaInfo.id
    const data = useSelector(dealsSelector.selectData)

    useEffect(() => {
        if (dfaId) {
            reduxDispatch(getDfaDealsById(dfaId))
        }
        return () => {
            reduxDispatch(clearDfaDeals())
        }
    }, [dfaId])

    const onRow = (deal: UnionDealFront) => ({
        onClick: async () => {
            const type = deal.type
            if (isEmissionDeal(deal)) {
                return emissionModal({ deal })
            }
            if (isSecondaryDeal(deal)) {
                return secondaryModal({ deal, type })
            }
            if (isRepaymentDeal(deal)) {
                return repaymentModal({ deal })
            }

            if (isTransferDeal(deal)) {
                return transferModal({ deal, type })
            }

            if (deal.type === 'transferV2') {
                transferV2Modal({ uuid: deal.uuid, user_uuid: uuid })
            }
        },
    })

    return (
        <Table
            isLoading={false}
            columns={gridConfig}
            onRow={onRow}
            clickableRow
            dataSource={data}
        />
    )
}
