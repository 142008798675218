import { DfaStatusEnum } from '@dltru/dfa-models'
import { Button, CaseAdd } from '@dltru/dfa-ui'
import { FC, memo, useState } from 'react'

import { SecondaryTradeBuyingModal } from '@components/Modals/Orders'

import { IActionProps } from './type'

export const SellDfaAction: FC<IActionProps> = memo(({ isPartiallyBlocked, dfa }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const permitOperation = Boolean(dfa?.balance?.active_amount_dfa)
    const isActionAvailable = DfaStatusEnum.emitment_success === dfa?.status && permitOperation

    if (isActionAvailable) {
        return (
            <>
                <Button
                    icon={<CaseAdd />}
                    borderRadius={16}
                    type="primary"
                    onClick={() => {
                        if (dfa.id) {
                            setIsModalVisible(true)
                        }
                    }}
                    disabled={isPartiallyBlocked}
                    tooltipOnDisable="Действие заблокировано. Обратитесь в службу поддержки."
                >
                    Продать ЦФА
                </Button>

                {isModalVisible && (
                    <SecondaryTradeBuyingModal
                        isCreate
                        isModalVisible={isModalVisible}
                        setIsModalVisible={setIsModalVisible}
                        selling={true}
                        assetId={dfa.id}
                    />
                )}
            </>
        )
    }

    return null
})
