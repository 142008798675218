import {
    IEarlyRepaymentItem,
    EEarlyRepaymentStatus,
    EarlyRepaymentStatusColors,
} from '@dltru/dfa-models'
import { ColumnsType, DateTimeCell, EMPTY_FIELD_VALUE, LinkCell, Tag, VIEW_DATE_FORMAT } from '@dltru/dfa-ui'
import dayjs from 'dayjs'

export const gridConfig: ColumnsType<IEarlyRepaymentItem> = [
    {
        title: 'Дата и время заявки',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        ellipsis: true,
        render: DateTimeCell,
    },
    {
        title: 'Срок погашения по заявке',
        dataIndex: 'valid_until',
        key: 'valid_until',
        sorter: true,
        ellipsis: true,
        render: v => {
          return dayjs(v).format(VIEW_DATE_FORMAT)
        },
    },
    {
        title: 'Владелец',
        dataIndex: 'user_uuid',
        key: 'user_uuid',
        ellipsis: true,
        render: (value: string, record: IEarlyRepaymentItem) =>
            LinkCell(record?.user_full_name || value, `/clients/${value}`),
    },
    {
        title: 'Количество ЦФА, ед.',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        ellipsis: true,
        render: (value: number) => value?.toLocaleString(),
    },
    {
        title: 'Цена погашения, ₽',
        dataIndex: 'price_per_dfa',
        key: 'price_per_dfa',
        sorter: true,
        ellipsis: true,
        render: (value: number) => {
            if (value) {
                return (value / 100).toLocaleString()
            }

            return EMPTY_FIELD_VALUE
        },
    },
    {
        title: 'Сумма погашения, ₽',
        dataIndex: 'redeem_total_price',
        key: 'redeem_total_price',
        sorter: true,
        ellipsis: true,
        render: (value: number) => {
            if (value) {
                return (value / 100).toLocaleString()
            }

            return EMPTY_FIELD_VALUE
        },
    },

    {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        ellipsis: true,
        render: (value: EEarlyRepaymentStatus) => {
            const tagProps = EarlyRepaymentStatusColors[value]
            return <Tag color={tagProps.color}>{tagProps.title}</Tag>
        },
    },
]
